'use strict';

angular.module('enervexSalesappApp').controller('JobInvitesCtrl', function($scope, $stateParams, _, Job, Account, Invitation, $q, Role, Util) {
	$scope.jobLabel = Util.jobLabel
	$scope.accountId = $stateParams.accountId;
	$scope.jobId = $stateParams.jobId;
	$scope.roles = Role.query({}).$promise.then(function(roles){
		$scope.roles = _.filter(roles, function(role){
			return !role.permissions.owner
		});
		$scope.invites = [
			{email: '', role:""},
			{email: '', role:""},
			{email: '', role:""}
		];
	})
	Job.get({
		id: $stateParams.jobId,
		accountId: $stateParams.accountId
	}).$promise.then(function(res){
		$scope.job = res;
	});
	Account.get({
		id: $stateParams.accountId
	}).$promise.then(function(res){
		$scope.account = res;
	});
	$scope.message = {};
	$scope.sendInvites = function(){
		var newInvites = _.map($scope.invites, function(invite){
			if (invite.email !='') {
				return _.defaults({
					job: $stateParams.jobId,
					message: $scope.message.newMessage
				}, invite)
			}
		})
		newInvites = _.compact(newInvites)
		var missing = _.find(newInvites,function(newInvite){
			return newInvite.role == "";
		})
		if (missing){
			alert("Please select a role for all invites")
			return
		}
		var promises = _.map(newInvites, function(newInvite){
			return Invitation.save({
				accountId: $stateParams.accountId,
				jobId: $stateParams.jobId
			}, newInvite).$promise
		})
		$q.all(promises).then(function(){
			$scope.invites = [
				{email: '', role:$scope.roles[0]},
				{email: '', role:$scope.roles[0]},
				{email: '', role:$scope.roles[0]}
			];
			$scope.message = {};
			setTimeout(function() {
				getInvites();
			},2000);
		})
	}
	$scope.removeFromTeam = function(member){
		$scope.job.members = _.filter($scope.job.members, function(m){
			return m._id != member._id
		});
		Job.update({
			id: $stateParams.jobId,
			accountId: $stateParams.accountId
		},{
			members: $scope.job.members
		}).$promise.then(function(res){
			$scope.job = res;
		});
		$scope.removeConfirm = false;
	}
	$scope.checkEmails = function(){
		if($scope.invites[$scope.invites.length - 1].email != '') {
			$scope.invites.push({email: ''})
		}
	}
	$scope.removeInvite = function(invitation){
		Invitation.remove({
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId,
			id: invitation._id
		}).$promise.then(function(res){
			getInvites()
		})
	}

	getInvites();
	function getInvites(){
		Invitation.query({
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId,
			status:'pending'
		}).$promise.then(function(invites){
			$scope.internalInvites = invites;
		})
		Job.get({
			id: $stateParams.jobId,
			accountId: $stateParams.accountId
		}).$promise.then(function(res){
			$scope.job = res;
		});
	}

});
